import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Avatar,
  Stack,
  Box,
  Button,
  Grid
} from '@mui/material';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { signOut } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [telegramToken, setTelegramToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      const unsubscribe = onSnapshot(userRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setUserData(docSnapshot.data());
          setLoading(false);
        } else {
          console.error('User document does not exist');
          setLoading(false);
        }
      });

      return () => unsubscribe(); // Cleanup listener on unmount
    }
  }, []);

  const handleConnectTelegram = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userRef = doc(db, 'users', user.uid);
        let token = userData?.telegramToken;

        if (!token) {
          token = uuidv4();
          await updateDoc(userRef, { telegramToken: token });
        }

        const botLink = `https://t.me/xosumam_bot?start=${token}`;
        setTelegramToken(token);
        window.location.href = botLink;
      } catch (error) {
        console.error('Error creating Telegram token:', error);
      }
    }
  };

  const formatRemainingTime = (seconds) => {
    if (!seconds || seconds <= 0) return '0 վայրկյան';

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    let formattedTime = '';
    if (hours > 0) formattedTime += `${hours}ժ `;
    if (minutes > 0 || (hours > 0 && secs > 0)) formattedTime += `${minutes}ր `;
    if (secs > 0 || (!hours && !minutes)) formattedTime += `${secs}վրկ`;

    return formattedTime.trim();
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card sx={{ padding: 3, boxShadow: 3, maxWidth: 500, margin: '0 auto', mt: 5 }}>
      <CardContent>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Avatar
            src={userData?.photoURL || ''}
            alt="User Profile"
            sx={{ width: 80, height: 80, boxShadow: 2 }}
          />
          <Box textAlign="center">
            <Typography variant="h6">{userData?.displayName || 'Անանուն'}</Typography>
            <Typography color="text.secondary">{userData?.email}</Typography>
          </Box>
        </Stack>
        <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">
            Մնացել է {formatRemainingTime(userData?.remaining_seconds)}
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 2, backgroundColor: '#ff5722', '&:hover': { backgroundColor: '#e64a19' } }}
            onClick={() => navigate('/pricing')}
          >
            Ավելացնել
          </Button>
        </Box>

        <Box mt={4}>
          {userData?.telegramId ? (
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TelegramIcon color="primary" sx={{ fontSize: 40 }} />
              </Grid>
              <Grid item xs>
                <Typography variant="body1">
                  Տելեգրամը կապված է ձեր հաշվի հետ:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Username: {userData.telegramUserName || 'անհայտ'}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TelegramIcon color="primary" sx={{ fontSize: 40 }} />
              </Grid>
              <Grid item xs>
                <Typography variant="body1">
                  Կապեք Տելեգրամը ձեր հաշվին՝ ձայնային հաղորդագրությունները վերծանելու համար:
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 1 }}
                  onClick={handleConnectTelegram}
                >
                  Կապել Տելեգրամը
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>

        <Box mt={4} textAlign="center">
          <Button variant="outlined" color="error" onClick={() => signOut(auth)} size="small">
            Դուրս գալ
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Settings;
