// src/pages/PaymentSuccess.js
import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

const PaymentSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Track payment success
    amplitude.track('Payment Successful', {
      message: 'Payment completed successfully',
    });
  }, []);

  const handleGoToSettings = () => {
    // Track navigation to settings
    amplitude.track('Navigated to Settings', {
      source: 'Payment Success Screen',
    });
    navigate('/settings'); // Redirecting to the settings page
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom>
        Վճարումը հաջողվեց
      </Typography>
      <Typography variant="body1" gutterBottom>
        Այժմ կարող եք ավելի շատ խոսք վերածել տեքստի ավելի բարձր որակով։
      </Typography>
      <Typography variant="body2" gutterBottom>
        Հարցերի կամ խնդիրների դեպքում կապնվեք մեզ support@xosum.am հասցեով, կամ գրեք հաղորդագրություն Տելեգրամին @kargyum
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoToSettings} sx={{ mt: 2 }}>
        Անցնել կարգավորումներ
      </Button>
    </Box>
  );
};

export default PaymentSuccess;
